<template>
  <div>
    <el-row>
      <el-select v-model="itemType" filterable placeholder="选择检测项 查看下表最新数据">
        <el-option v-for="item in allGroupInfo" :key="item.itemType" :label="item.deviceProtocolName" :value="item.itemType"> </el-option>
      </el-select>
      <el-button class="el-icon-search mr_10" @click="findTaskNewDataFun">查询最新数据</el-button>
    </el-row>
    <PointList :dataSource="dataSource" :protocol="protocol" :loading="loading"></PointList>
  </div>
</template>

<script>
import PointList from '@/components/PointList.vue'
import { findTaskNewData } from '@/api/data.js'
import { findAllPoint } from '@/api/Point.js'
import { findAllPointV3 } from '@/api/PointV3.js'

const dataSource = []
const allGroupInfo = [
  {
    deviceProtocolName: 'V_沉降监测',
    deviceProtocol: 'HighMoldLaserVItem',
    groupName: '沉降',
    itemType: 'V',
    nodeType: 'VF',
    data: [],
  },
  {
    deviceProtocolName: 'F_轴力监测',
    deviceProtocol: 'HighMoldAxialFItem',
    groupName: '轴力',
    itemType: 'F',
    nodeType: 'VF',
    data: [],
  },
  {
    deviceProtocolName: 'L_倾角监测',
    deviceProtocol: 'HighMoldInclinationLItem',
    groupName: '倾角',
    itemType: 'L',
    nodeType: 'HL',
    data: [],
  },
  {
    deviceProtocolName: 'H_水平位移监测',
    deviceProtocol: 'HighMoldLaserHItem',
    groupName: '位移',
    itemType: 'H',
    nodeType: 'HL',
    data: [],
  },
  {
    deviceProtocolName: 'V_沉降监测(V3)',
    deviceProtocol: 'HighMoldLaserVItemV3',
    groupName: '沉降',
    itemType: 'VV3',
    nodeType: 'VF',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'F_轴力监测(V3)',
    deviceProtocol: 'HighMoldAxialFItemV3',
    groupName: '轴力',
    itemType: 'FV3',
    nodeType: 'VF',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'L_倾角监测(V3)',
    deviceProtocol: 'HighMoldInclinationLItemV3',
    groupName: '倾角',
    itemType: 'LV3',
    nodeType: 'HL',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'H_水平位移监测(V3)',
    deviceProtocol: 'HighMoldLaserHItemV3',
    groupName: '位移',
    itemType: 'HV3',
    nodeType: 'HL',
    versions: 3,
    data: [],
  },
]
export default {
  data() {
    return {
      itemType: 'V',
      dataSource,
      allGroupInfo,
      protocol: 'HighMoldLaserVItem',
      total: 100,
      loading: false,
      itemList: [],
    }
  },
  components: { PointList },
  watch: {
    itemType(e) {
      this.getSelectData(e)
      this.findTaskNewDataFun()
    },
  },
  methods: {
    // 查询任务所有测点
    async findAllPointFun() {
      const { data: res } = await findAllPoint(this.$route.params.projectId - 0)
      const { data: resV3 } = await findAllPointV3(this.$route.params.projectId - 0)
      console.log('获取测点相关信息', res.data)

      this.allGroupInfo[0].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '沉降监测'
      })
      this.allGroupInfo[1].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '轴力监测'
      })
      this.allGroupInfo[2].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '倾角监测'
      })
      this.allGroupInfo[3].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '位移监测'
      })

      this.allGroupInfo[4].data = resV3.data.filter((item) => {
        return item.monitoringType === '沉降监测'
      })
      this.allGroupInfo[5].data = resV3.data.filter((item) => {
        return item.monitoringType === '轴力监测'
      })
      this.allGroupInfo[6].data = resV3.data.filter((item) => {
        return item.monitoringType === '倾角监测'
      })
      this.allGroupInfo[7].data = resV3.data.filter((item) => {
        return item.monitoringType === '位移监测'
      })

      this.getSelectData(this.allGroupInfo[0].itemType)
    },
    // 获取任务最新数据
    async findTaskNewDataFun() {
      this.loading = true
      const { data: res } = await findTaskNewData(this.itemType, this.$route.params.projectId - 0)
      console.log(res)
      const pointList = []
      this.itemList.forEach((item, index) => {
        let pointData = res.data[index] || {}
        pointData.pointName = item.pointName
        pointList.push(pointData)
      })
      this.dataSource = pointList
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    // 初始化数据
    async init() {
      await this.findAllPointFun()
      await this.findTaskNewDataFun()
    },
    getSelectData(e) {
      const data = this.allGroupInfo.filter((item) => {
        return item.itemType === e
      })
      console.log('获取的data', data)
      this.itemList = data[0].data
      this.protocol = data[0].deviceProtocol
      console.log(this.itemList, this.protocol)
    },
  },
  created() {
    this.init()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.el-row {
  // height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  // line-height: 40px;
  .mr_10 {
    margin-left: 10px;
  }
}
</style>
